<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="authentication d-flex flex-column flex-lg-row flex-column-fluid bg-white"
    >
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #5145cd"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-20 pt-15">
          <a :href="homeurl" class="text-center mb-10">
            <img src="media/logos/pilot-logo.png" class="max-h-70px" alt="" />
          </a>
          <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
            style="color: #ffffff"
          >
            The Most Advanced Cloud ERP<br />
            For Health, Safety & Environment.
          </h3>
        </div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          style="background-size: 450px; background-position: top"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <div>
            <h1 class="text-center mb-5" v-html="message">
              <span
                v-if="countDown > 0"
                class="text-primary font-weight-bolder cursor-pointer"
              >
                {{ countDown }}
              </span>
            </h1>
            <p class="text-center">
              <b-spinner
                v-if="loading == true"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </p>
            <p class="text-center">
              <span
                v-if="isInvalidToken == true && loading == false"
                class="text-primary font-weight-bolder cursor-pointer"
                @click="resendConfirmation()"
              >
                Resend Confirmation Mail
              </span>
            </p>
            <p class="text-center">
              <span
                v-if="canRetry == true"
                class="text-primary font-weight-bolder cursor-pointer"
                @click="retry()"
              >
                Retry
              </span>
            </p>
          </div>
        </div>
        <div
          class="d-flex justify-content-lg-start justify-content-center align-items-end py-4 py-lg-0"
        >
          <a
            @click="privacy = true"
            class="text-primary font-weight-bolder font-size-h5 cursor-pointer"
            >Terms & Conditions</a
          >
          <a 
            @click="terms = true" 
            class="text-primary ml-10 font-weight-bolder font-size-h5 cursor-pointer"
            >Privacy Policy</a
          >
        </div>
      </div>
    </div>
    <b-modal
      v-model="privacy"
      size="xl"
      id="privacy"
      scrollable
      title="Privacy Policy"
    >
      <p><strong>General</strong></p>
      <p>
        In this Privacy Policy (“Policy”), we describe how Pilot HSE ERP
        (“Pilot”) collects, uses, and discloses information that we obtain about
        visitors to our website and users of our platform. By visiting our
        website, platform and services, you agree that your personal information
        will be handled as described in this Policy. Your use of our Site or
        Services, and any dispute over privacy, is subject to this Policy and
        our Terms of Service, including its applicable limitations on damages
        and the resolution of disputes.
      </p>

      <p><strong>Information We Collect About You</strong></p>
      <p>
        We collect information about you directly from you and from other
        entities, and automatically through your use of our Site or Services.
        The information we collect from you depends on how you use our Services.
      </p>

      <p><strong>Information We Collect Directly From You</strong></p>
      <p>
        We collect the following information from you in the following
        scenarios:<br />
        When you create an account, we collect your name, email, company name
        and details and a password. We will also ask for your telephone number.
        We may also collect information from you about other users who you would
        like to authorize to our services.
      </p>
      <p>
        We collect information from you when you contact us for customer service
        or other purposes. The information we collect depends on the nature of
        your contact and may include your name, email address, other contact
        information, and nature of your inquiry. If you call us, we may record
        our call with you for customer service and training purposes.
      </p>
      <p>
        If your employer or organization signs up, we may obtain information
        about individuals authorized to use the product. If a user of our
        platform invites you to join their project, we will receive information
        about you from that user, including the information we need to create
        your account, such as your name and email address.
      </p>
      <p>
        We and our service providers automatically collect information about
        your use of our platform through cookies and other technologies. To the
        extent permitted by applicable law, we combine this information with
        other information we collect about you, including your personal
        information. We may associate this information with your account.
      </p>
      <p>
        When you use our platform we collect entries you make around waste
        streams, waste volumes, unsafe acts and conditions, energy sources and
        quantities consumed and action parties you assign to close out observed
        actions.
      </p>
      <p>
        When you visit our Site, we automatically collect the following
        information about you: domain name, your browser type and operating
        system, device information, IP address, web pages you view on the site
        and platform, links you click on the Site, the length of time you visit
        our Site or use our Services, the referring URL, or the webpage that led
        you to our Site and your location information based on your IP address.
      </p>
      <p>
        We will also collect location information you enter such as the location
        of hazards identified, or location of your worksites you provided.
      </p>
      <p>
        We will use automatically collected information obtained through a
        customer’s and its users’ use of our platform to assist us in evaluating
        our services for troubleshooting, customer service purposes, and product
        improvement.
      </p>
      <p>
        We use your information to provide our services to you and to complete
        actions you take on our platform, to communicate with you about your use
        of our platform and to provide you with product-related communications,
        to respond to your inquiries, and for other customer service purposes.
        Where permitted by applicable law, we use your telephone number to send
        you service related text messages.
      </p>
      <p>
        We also use information you provide to send you newsletters and carry
        out email marketing about our products and to assist us with marketing
        on our affiliates websites. We may also use information you provide to
        evaluate the success of our adverting campaigns through other channels
        (including our online targeted advertising and offline promotional
        campaigns). We also information you provide to better understand how
        users access and use our platform, for Research, Development and
        Analytical Purposes aimed at improving our products and service
        offerings.
      </p>
      <p>
        We may disclose the information we collect from you to service
        providers, contractors or agents who perform functions on our behalf.
        These service providers include entities that assist us in performing
        service functions on our behalf such as customer support, entities that
        perform marketing services on our behalf, and entities that provide
        analytics or information security services for us.
      </p>
      <p>
        We engage entities to assist us with online targeted advertising, and we
        may share information with those entities. We also disclose the
        information we collect from you to our affiliates or subsidiaries.
      </p>
      <p>
        Partners. As discussed in more detail above, we share information with
        our partners in accordance with the terms of our agreement with each
        customer. Our partners may market their services directly to you.
        <br /><br />
      </p>
      <p>
        We also disclose information in the following circumstances:<br />
        We disclose your information to comply with the law, a judicial
        proceeding, court order, or other legal process, such as in response to
        a court order or a subpoena.
      </p>
      <p>
        We disclose your information when we believe it is appropriate to do so
        to investigate, prevent, or take action regarding illegal activities,
        suspected fraud, situations involving potential threats to the safety of
        any person, violations of our Terms of Service or this Policy, or as
        evidence in litigation in which we are involved. We share aggregate,
        anonymized, or de-identified information about users with other entities
        for marketing, advertising, research or similar purposes.
      </p>
      <p>
        We do not share information with unaffiliated entities for their own
        direct marketing purposes.
      </p>

      <p><strong>Our Use of Cookies and Other Tracking Mechanisms</strong></p>
      <p>
        We and our service providers use cookies and other tracking mechanisms
        to track information about your use of our platform. We may combine this
        information with other personal information we collect from you (and our
        service providers may do so on our behalf).
      </p>
      <p><strong>Cookies</strong></p>
      <p>
        Cookies are alphanumeric identifiers that we transfer to your device’s
        hard drive through your web browser for record-keeping purposes. Some
        cookies allow us to make it easier for you to navigate our website and
        platform.
      </p>
      <p>
        Most web browsers automatically accept cookies, but if you prefer, you
        can edit your browser options to block them in the future. The Help
        portion of the toolbar on most browsers will tell you how to prevent
        your computer from accepting new cookies, how to have the browser notify
        you when you receive a new cookie, or how to disable cookies altogether.
        Users of our platforms who disable cookies will be able to browse
        certain areas of the Site, but some features may not function.
      </p>
      <p><strong>Analytics</strong></p>
      <p>
        We use automated devices and applications, such as Google Analytics, to
        evaluate usage of our platforms. We also may use other analytic means.
        We use these tools to help us improve our product’s performance and user
        experiences. The entities operating these tools may use cookies and
        other tracking technologies, such as web beacons or local storage
        objects (LSOs), to perform their services.
      </p>
      <p><strong>Cross-Device Use</strong></p>
      <p>
        We and our service providers, including Google, may use the information
        that we collect about you to help us and our service providers identify
        other devices that you use. We, and our service providers also may use
        the cross-device use and other information we learn about you to serve
        targeted advertising on your devices and to send you emails.
      </p>
      <p><strong>Information Security</strong></p>
      <p>
        We have implemented reasonable precautions to protect the information we
        collect from loss, misuse, and unauthorized access, disclosure,
        alteration, and destruction. Please be aware that despite our efforts,
        no data security measures can guarantee security. You should take steps
        to protect against unauthorized access to your password, phone, and
        computer by, among other things, signing off after using a shared
        computer, choosing a robust password that nobody else knows or can
        easily guess, and keeping your log-in and password private. We are not
        responsible for any lost, stolen, or compromised passwords or for any
        activity on your account via unauthorized password activity.
      </p>
      <p>
        You may modify personal information that you have submitted by logging
        into your account and updating your profile information.
      </p>
      <p>
        We may send periodic promotional emails to you. You may opt-out of
        promotional emails by following the opt-out instructions contained in
        the email. Please note that it may take up to 10 business days for us to
        process opt-out requests. If you opt-out of receiving promotional
        emails, we may still send you emails about your account or any services
        you have requested or received from us.
      </p>
      <p><strong>Contact Us</strong></p>
      <p>
        If you have questions about the privacy aspects of our Services or would
        like to make a complaint, please contact us at support@Pilothse.com.
      </p>

      <p><strong>Changes to this Policy</strong></p>
      <p>
        We may change this Policy from time to time, so please be sure to check
        back periodically. We will post any changes to this Policy on our
        website. If we make any changes to this Policy that materially affect
        our practices with regard to the personal information we have previously
        collected from you, we will endeavor to provide you with notice in
        advance of such change by highlighting the change on our website.
      </p>
    </b-modal>

    <b-modal
      v-model="terms"
      size="xl"
      id="terms"
      scrollable
      title="Terms &amp; Conditions"
    >
      <p>
          <strong>THESE TERMS AND CONDITIONS (“TERMS”) ARE A LEGAL CONTRACT BETWEEN YOU AND
              PILOTHSE.COM. (“COMPANY”, “WE”, OR “US”).

              THE TERMS EXPLAIN HOW YOU ARE PERMITTED TO USE THE PILOTHSE WEBSITE, THE ERP
              PLATFORM AND OTHER RELATED SERVICES OFFERED BY THE COMPANY COLLECTIVELY REFERRED TO
              AS THE “SERVICE”. BY USING ANY OF THE SERVICE, YOU ARE AGREEING TO ALL THE TERMS; IF
              YOU DO NOT AGREE WITH ANY OF THESE TERMS, DO NOT ACCESS OR OTHERWISE USE THE
              SERVICE.
          </strong>
      </p>

      <p><strong>Plans, Customers and Users</strong></p>
      <p>
          The Service offers a free plan. This free plan provides access to the Service Observer,
          Waste and Energy Management modules.
      </p>

      <p>“Customer” refers to the organization or individual that you represent when signing up.
      </p>
      <p>
          The Customer can create and modify a company profile, add, modify and re-assign users
          within that select company. “Users” refer to individuals with access to the Service in
          connection with a Customer’s plan.
      </p>
      <p>
          References herein to “you” refer to both Users and Customers. By registering or using
          the Service, you represent that you are at least the age of majority in the jurisdiction
          in which you reside, and you agree to these Terms as a User. In addition, you represent
          that you are authorized to act on behalf of Customer, and you hereby bind Customer to
          these Terms.
      </p>
      <p>
          Customer is responsible for the conduct of Users under the Customer’s plan and their
          compliance with these Terms. Any non-compliance or breach of these Terms by a Customer’s
          User is also a non-compliance or breach by Customer.
      </p>
      <p><strong>Changes</strong></p>
      <p>
          Company may make changes to the Service at any time. Company can change, update, or add
          or remove provisions of these Terms, at any time by posting the updated Terms on the
          Service. By using the Service after Company has updated the Terms, you are agreeing to
          all the updated Terms. If you do not agree with any of the updated Terms, you must stop
          using the Service.
      </p>
      <p><strong>General Use</strong></p>
      <p>
          Company provides content through the Service that is copyrighted and/or trademarked work
          of Company or Company’s third-party licensors and suppliers or other Users of the
          Service (collectively, the “Materials”). Materials may include data, information, text,
          logos, graphics, video, audio, images, software and other content.
      </p>
      <p>
          Subject to the terms and conditions of these Terms, and your compliance with these
          Terms, Company hereby grants you a limited, non-exclusive and non-transferable license
          to use and to display the Materials and to use the Service solely as intended through
          the provided functionality of the Service. Except for the foregoing license, you have no
          other rights in the Service or any Materials and you may not modify, edit, copy,
          reproduce, create derivative works of, reverse engineer, alter, enhance or in any way
          exploit any of the Service or Materials in any manner. Upon the termination of your
          account, the above license terminates automatically.
      </p>
      <p>
          <strong>You may not:</strong>
          <ul class="pl-5 text-dark">
              <li>Modify, disassemble, decompile or reverse engineer the Service, except to the extent
                  that such restriction is expressly prohibited by law;</li>
              <li>Rent, lease, loan, resell, sublicense, distribute or otherwise transfer the Service
                  to any third-party </li>
              <li>Make any copies of the Service</li>
              <li>Remove, circumvent, disable, damage or otherwise interfere with security-related
                  features of the Service, features that prevent or restrict use or copying of any
                  content accessible, or features that enforce limitations on use of the Service</li>
              <li>Delete the copyright and other proprietary rights notices on the Service</li>
          </ul>
      </p>
      <p>
          You acknowledge that Company may from time to time issue upgraded versions of the
          Service and may automatically electronically upgrade the version that you are using. You
          consent to such automatic upgrades and agree that these Terms will apply to all such
          upgrades.
      </p>
      <p><strong>Using the Service</strong></p>
      <p>
          In order to access certain password-restricted areas of the Service, you must register
          with Company for an account.
      </p>
      <p>
          You may register for an account with the Company through the account registration page
          on the Site. You are responsible for maintaining the confidentiality of your login and
          password (“Password”), and you are responsible for all activities that occur using your
          Password. You agree not to share your Password, let others access or use your Password
          or do anything else that might jeopardize the security of your Password. You agree to
          promptly notify Company if your Password on the Service is lost, stolen, if you are
          aware of any unauthorized use of your Password on the Service or if you know of any
          other breach of security in relation to the Service.
      </p>
      <p>
          All the information that you provide when registering for an account and otherwise
          through the Service must be accurate, complete and up to date. You may change, correct
          or remove certain information from your account by logging into your account directly
          and making the desired changes.
      </p>
      <p><strong>User Submissions</strong></p>
      <p>
          The Service allows Users to submit content, including, without limitation, information
          regarding Customer’s safety management, energy and waste management (“Submissions”).
          When you make Submissions to the Service, you represent, warrant and covenant that:
      </p>
      <p>
          Your Submissions are related to the intended purpose of the Service, which is to
          facilitate safety management.
      </p>
      <p>
          The use, creation, modification, distribution, transmission, display or performance, and
          the accessing, downloading, or copying of your Submission does not and will not infringe
          or violate the rights of any third party, including, but not limited to, copyright,
          patent, trademark, trade secret, moral rights, and privacy rights.
      </p>
      <p>
          You are the creator and owner of, or have the necessary licenses, rights, consents,
          releases, and permissions to use and to authorize Company, the Service, and other Users
          of the Service to use your Submission in any manner contemplated by the Service and
          these Terms.
      </p>
      <p>
          Your Submission is not false, inaccurate, or misleading.
      </p>
      <p>
          Your Submission does not violate any applicable law, regulation, or rule.
      </p>
      <p>
          Company reserves the right to remove any Submission by you if Company determines, in
          Company’s sole discretion, that your Submission is inappropriate for the Service.
      </p>
      <p>
          You hereby grant Company a worldwide, non-exclusive, irrevocable, royalty-free, fully
          paid-up, sublicenseable and transferable license to use, reproduce, distribute, prepare
          derivative works of, display, and perform your Submissions to provide Service to the
          Customer you are associated with consistent with the functionality of the Service. This
          license continues even if you stop using the Service.
      </p>
      <p>
          Customer is free to modify, download and delete submissions at customers discretion.
          Customer is solely responsible for and shall indemnify Company from and against any
          liability, cost, loss, lawsuit, claim, investigation, sanction or expense (including
          attorney’s fees) that arises out of the removal of any Submission by or at the request
          of the Customer.
      </p>
      <p><strong>Compliance with Laws and Unauthorized Activities</strong></p>
      <p>
          When using the Service, you agree to comply with all applicable laws, including, without
          limitation, employment, workplace safety, and privacy and data protection laws. You are
          responsible for ensuring that your Submission and practices relating to workplace safety
          comply with all applicable laws, rules, regulations and orders, including without
          limitation, the Occupational Safety and Health Act of 1970 (OSHA) and implementing
          regulations thereunder and any equivalent state laws. You are solely responsible for
          making all reports required by OSHA and applicable laws. You are responsible for
          ensuring that you maintain any and all records, including all Submission, required under
          the OSHA recordkeeping rules and under other applicable laws.
      </p>
      <p>
        <strong>When using the Service, you agree not to:</strong>
        <ul class="pl-5 text-dark">
            <li>Use the Service for any illegal, unlawful or unauthorized purpose.</li>
            <li>Circumvent, disable, or otherwise interfere with any security-related features of
                the Service, including features that prevent or restrict the use or copying of any
                Materials or enforce limitations on the use of the Service and/or the Materials
                contained therein.</li>
            <li>Engage in unauthorized framing of or linking to the Service.</li>
            <li>Attempt to learn sensitive information about Company and/or other Users of the
                Service, such as User passwords.</li>
            <li>Engage in any automated use of the Service, such as using scripts to send comments
                or messages, or using any data mining, robots, or similar data gathering and
                extraction tools.</li>
            <li>Interfere with, disrupt, or create an undue burden on the Service or the networks or
                services connected to the Service.</li>
            <li>Attempt to impersonate another User, or use the Username of another User, of the
                Service.</li>
            <li>Sell or otherwise transfer your User profile on the Service to a third party.</li>
            <li>Use the Service as part of any effort to compete with Company.</li>
            <li>Decipher, decompile, disassemble, or reverse engineer any of the software comprising
                or in any way making up a part of the Service.</li>
            <li>Copy or adapt the Service’s software.</li>
            <li>Delete the copyright or other proprietary rights notices from any Materials.</li>
        </ul>
      </p>
      <p><strong>Service Management</strong></p>
      <p>
          Company reserves the right, but not the obligation, to:
      </p>
      <p>
          Monitor the Service for violations of these Terms.
      </p>
      <p>
          In Company’s sole discretion, and without limitation, refuse or restrict your access to
          the Service, or limit the availability of the Service to you, if you breach the law or
          these Terms.
      </p>
      <p>
          Take appropriate legal action against anyone who, in Company’s sole discretion, violates
          the law or these Terms, including without limitation, reporting such User to law
          enforcement authorities.
      </p>
      <p>
          In Company’s sole discretion, and without limitation, refuse, restrict access to, limit
          the availability of, or disable (to the extent technologically feasible) any of your
          Submissions or any portion thereof, if you breach the law or these Terms.
      </p>
      <p>
          Company cannot guarantee the Service will be available to you at all times. Company
          and/or its system’s aggregator may experience hardware, software, or other problems or
          may need to perform maintenance related to the Service, resulting in interruptions,
          delays, or errors. You may also experience problems connecting to the internet, and
          therefore to the Service. While the Company will take reasonable steps to make the
          Service available, you agree not to hold Company liable whatsoever for any loss, damage,
          or inconvenience howsoever arising caused by your inability to access or use the Service
          during any downtime or discontinuance of the Service.
      </p>
      <p><strong>Privacy Policy</strong></p>
      <p>
          Please review Company’s Privacy Policy (“Privacy Policy”) which explains how Company
          uses information that you submit to Company.
      </p>

      <p><strong>Proprietary Rights</strong></p>
      <p>
          PilotHSE is a trademark of Company. Other trademarks, names and logos on the Service are
          the property of their respective owners.
      </p>
      <p>
          Unless otherwise specified in these Terms, all information and screens appearing on the
          Service, including documents, services, site design, text, graphics, logos, images and
          icons, as well as the arrangement thereof, are the sole property of Company, Copyright ©
          2021 PilotHSE. All rights not expressly granted herein are reserved. Except as otherwise
          required or limited by applicable law, any reproduction, distribution, modification,
          retransmission, or publication of any copyrighted material is strictly prohibited
          without the express written consent of the copyright owner or license.
      </p>

      <p><strong>Disclaimer of Warranties</strong></p>
      <p>
          Your use of the Service is at your own risk. While Company endeavors to ensure the
          functionality of the Service, the Materials have not been verified or authenticated in
          whole or in part by the Company, and they may include inaccuracies or typographical or
          other errors. Company does not warrant the accuracy or timeliness of the Materials
          contained on the Service. To the extent permitted by applicable laws, Company has no
          liability for any errors or omissions in Materials, whether provided by Company, our
          licensors or suppliers or other Users.
      </p>
      <p>
          TO THE EXTENT PERMITTED BY APPLICABLE LAWS, COMPANY MAKES NO EXPRESS, IMPLIED OR
          STATUTORY REPRESENTATIONS, WARRANTIES, OR GUARANTEES IN CONNECTION WITH THE SERVICE OR
          ANY MATERIALS RELATING TO THE QUALITY, SUITABILITY, TRUTH, ACCURACY OR COMPLETENESS OF
          ANY INFORMATION OR MATERIAL CONTAINED OR PRESENTED ON THE SERVICE, INCLUDING WITHOUT
          LIMITATION THE MATERIALS. UNLESS OTHERWISE EXPLICITLY STATED, TO THE MAXIMUM EXTENT
          PERMITTED BY APPLICABLE LAW, THE SERVICE, MATERIALS, AND ANY INFORMATION OR MATERIAL
          CONTAINED OR PRESENTED ON THE SERVICE IS PROVIDED TO YOU ON AN “AS IS,” “AS AVAILABLE”
          AND “WHERE-IS” BASIS WITH NO WARRANTY OF IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS
          FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF THIRD-PARTY RIGHTS. COMPANY DOES NOT
          PROVIDE ANY WARRANTIES AGAINST VIRUSES, SPYWARE OR MALWARE THAT MAY BE INSTALLED ON YOUR
          COMPUTER OR MOBILE DEVICE.
      </p>

      <p><strong>Limitation of Liability</strong></p>
      <p>
          WHILE COMPANY ENDEAVORS TO ENSURE THE FUNCTIONALITY OF THE SERVICE, COMPANY SHALL NOT BE
          LIABLE TO YOU FOR ANY DAMAGES RESULTING FROM YOUR USING, DISPLAYING, COPYING, OR
          DOWNLOADING ANY MATERIALS TO OR FROM THE SERVICE. TO THE MAXIMUM EXTENT PERMITTED BY
          APPLICABLE LAW, IN NO EVENT SHALL COMPANY BE LIABLE TO YOU FOR ANY INDIRECT,
          EXTRAORDINARY, EXEMPLARY, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES
          (INCLUDING LOSS OF DATA, REVENUE, PROFITS, USE OR OTHER ECONOMIC ADVANTAGE) HOWEVER
          ARISING, EVEN IF COMPANY KNOWS THERE IS A POSSIBILITY OF SUCH DAMAGE.
      </p>

      <p><strong>Indemnity</strong></p>
      <p>
          To the extent permitted by applicable law, Customer agree to indemnify and hold harmless
          Company from and against any and all claims, suits, actions, demands and proceedings
          against Company and all losses, costs, damages, expenses (including reasonable
          attorneys’ fees) and liabilities related thereto arising out of or related to: (i)
          Customer or its Users’ violation of these Terms, (ii) Customer or its Users’ Submission
          to the Service, (iii) any safety or other compliance decision or action taken by
          Customer based on the Service, or (iv) Customer or its Users’ violation of any third
          party right, including without limitation any copyright, property, or privacy right.
          This indemnification obligation will survive these Terms and Customer’s use of the
          Service.
      </p>

      <p><strong>Feedback</strong></p>
      <p>
          If you send or transmit any communications, comments, questions, suggestions, or related
          materials to Company, whether by letter, email, telephone, or otherwise (collectively,
          “Feedback”), suggesting or recommending changes to the Service or Materials, including,
          without limitation, new features or functionality relating thereto, all such Feedback
          is, and will be treated as, non-confidential and non-proprietary. Except as prohibited
          by applicable law, you hereby assign all right, title, and interest in, and Company is
          free to use, without any attribution or compensation to you, any ideas, know-how,
          concepts, techniques, or other intellectual property and proprietary rights contained in
          the Feedback, whether or not patentable, for any purpose whatsoever, including but not
          limited to, developing, manufacturing, having manufactured, licensing, marketing, and
          selling, directly or indirectly, products and services using such Feedback. Where the
          foregoing assignment is prohibited by law, you hereby grant Company an exclusive,
          perpetual, irrevocable, transferable, worldwide, royalty-free, fully paid up license
          (including the right to sublicense) to use and exploit all Feedback as Company may
          determine in its sole discretion. Notwithstanding the foregoing, you understand and
          agree that Company is not obligated to use, display, reproduce, or distribute any such
          ideas, know-how, concepts, or techniques contained in the Feedback, and you have no
          right to compel such use, display, reproduction, or distribution.
      </p>

      <p><strong>Termination of Terms Entered Into Between Company and User</strong></p>
      <p>
          The Terms entered into hereunder between Company and User remain effective until the
          applicable Customer’s plan expires or terminates, or User’s access to the Service has
          been terminated by Customer or Company.
      </p>
      <p>
          Upon the termination of the Terms entered into hereunder between Company and User, User
          shall cease accessing and using the Service, and Company may take such actions to
          preclude such access and use.
      </p>
      <p>
          The disclaimer of warranties, representations made by User, limitations of liability and
          general provisions shall survive any termination of the Terms entered into between
          Company and User.
      </p>

      <p><strong>Termination of Terms Entered Into Between Company and Customer</strong></p>
      <p>
          Company may terminate the Terms entered into hereunder between Company and Customer at
          any time and for any reason by terminating Customer’s account on the Service or giving
          written notice to Customer.
      </p>
      <p>
          Upon the termination of the Terms entered into hereunder between Company and Customer,
          Customer and its Users shall cease accessing and using the Service, and Company may take
          such actions to preclude such access and use.
      </p>
      <p>
          The disclaimer of warranties, representations made by Customer, indemnities, limitations
          of liability and general provisions shall survive any termination of the Terms entered
          into between Company and Customer.
      </p>

      <p><strong>General</strong></p>
      <p>
          If any of these Terms is found to be inconsistent with applicable law, then such term
          shall be interpreted to reflect the intentions of the parties, and no other terms will
          be modified. Company’s failure to enforce any of these Terms is not a waiver of such
          term. These Terms are the entire agreement between you and Company and supersede all
          prior or contemporaneous negotiations, discussions or agreements between you and Company
          about the Service.
      </p>

      <p><strong>Contact Us</strong></p>
      <p>
          If you have any questions about these Terms or otherwise need to contact Company for any
          reason, you can reach us at support@pilothse.com
      </p>
    </b-modal>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/login/login.scss";
</style>

<script>
import { mapGetters, mapState } from "vuex";
import {
  RESEND_CONFIRMATION,
  CONFIRMATION
} from "@/core/services/store/auth.module";

export default {
  name: "email-confirmation",
  data() {
    return {
      homeurl: "https://www.pilothse.com",
      privacy: false,
      terms: false,
      loading: true,
      canRetry: false,
      isInvalidToken: false,
      token: "",
      email: "",
      countDown: 5,
      retries: 0,
      message: "Please hold on while we verify your email address..."
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return process.env.BASE_URL + "media/svg/illustrations/confirmation.svg";
    }
  },
  mounted() {
    this.token = this.$route.query.token;
    this.email = decodeURIComponent(this.$route.query.email);

    this.confirmation();
  },
  methods: {
    confirmation() {
      if (this.token && this.email) {
        this.$store
          .dispatch(CONFIRMATION, {
            email: this.email,
            token: this.token
          })
          .then(() => {
            this.message =
              "Your email address has been confirmed. <br> <small>Hold tight we are getting you started</small>";
            this.countDown = 5;
            setTimeout(() => {
              this.countDownTimer();
              this.$router.push({ name: "onboarding-welcome" });
            }, 5000);
          })
          .catch(() => {
            this.loading = false;
            if (this.errors.status) {
              this.message =
                "We hit a snag while trying to verify your email address.";
              if (this.errors.status == 400) {
                this.makeToast(
                  "danger",
                  "Invalid Token",
                  "The token supplied by your page is either invalid or expired. Please resend confirmation email."
                );
              } else {
                this.makeToast(
                  "danger",
                  this.errors.statusText,
                  this.errors.data.message
                );
              }
              this.canRetry = false;
              this.isInvalidToken = true;
            } else {
              this.message =
                "We hit a snag while trying to verify your email address.";
              if (this.retries >= 3) {
                this.canRetry = false;
                this.isInvalidToken = true;
              } else {
                this.canRetry = true;
              }
              this.makeToast(
                "danger",
                "Oops",
                "An unexpected exception has occured. Please give it a moment and try later"
              );
            }
          });
      }
    },

    retry() {
      this.retries++;
      if (this.retries <= 3) {
        console.log(this.retries);
        this.canRetry = false;
        this.loading = true;
        this.confirmation();
      }
    },

    resendConfirmation() {
      if (this.currentUser) {
        this.loading = true;
        this.$store
          .dispatch(RESEND_CONFIRMATION, {
            email: this.email
          })
          .then(() => {
            this.makeToast(
              "success",
              "Confirmation Sent!",
              "The confirmation email has been resent to your registered email address"
            );
            this.loading = false;
            this.isInvalidToken = false;
            this.message =
              "We have sent another confirmation mail your way. <br > <small>Kindly hit the Confirm Email button in the email to confirm your email address</small>";
          })
          .catch(() => {
            if (this.errors.status) {
              this.makeToast(
                "danger",
                this.errors.statusText,
                this.errors.data.message
              );
              if (this.errors.status == 400) {
                this.isInvalidToken = false;
                this.loading = true;
                this.message =
                  "Your email address has been confirmed. <br> <small>Hold tight while we redirect you to the login page</small>";
                this.countDown = 5;
                setTimeout(() => {
                  this.countDownTimer();
                  this.$router.push({ name: "login" });
                }, 5000);
              }
              this.loading = false;
            } else {
              this.makeToast(
                "danger",
                "Oops",
                "An unexpected exception has occured. Please give it a moment and try later"
              );
            }
            this.loading = false;
          });
      }
    },

    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    },

    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    }
  }
};
</script>
